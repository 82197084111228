import React from 'react';
import { getDurationType } from '../../utils/helpers';

const ShowMedicineBlock = ({ medicines, type = null }) => {
    return (<table className="table no-padding current_medicine">

        <thead>
            <tr>
                <th></th>
                <th>Brand Name</th>
                <th>Generic Name</th>
                <th>Frequency</th>
                <th>Duration</th>
                <th>Meal</th>
                <th>Dosage</th>
                <th>Substitution</th>
            </tr>
        </thead>
        <tbody>
            {medicines && medicines.map((medicine, idx) => (
                <React.Fragment key={idx}>
                    <tr>
                        <td><span className="sr_no">{idx + 1}</span></td>
                        <td valign="top">{medicine.drug_name}</td>
                        <td valign="top">{medicine.drug_generic_name}</td>
                        <td valign="top">{medicine.dosage ? (medicine.dosage) : '--'}</td>
                        <td valign="top">{medicine.duration ? medicine.duration : '--'}
                            {medicine.duration_type ? getDurationType(medicine.duration_type) : '--'}</td>
                        <td valign="top">{medicine.meal ? (medicine.meal) : '--'}</td>
                        <td valign="top">{medicine.quantity ? medicine.quantity : '--'}</td>
                        <td valign="top">{medicine.is_generic_allowed ? "Yes" : "No"} </td>
                    </tr>
                    <tr><td></td><td colSpan="8"><span className="remark-heading">Instructions:</span>{medicine.remarks}</td></tr>
                    {type === 'current_medicine' && <tr><td></td><td colSpan="8"><span className="remark-heading">Side effects/Complaint of medicaion:</span>{medicine.side_effects}</td></tr>}
                </React.Fragment>
            ))}
        </tbody>
    </table>
    )
}
export default ShowMedicineBlock
