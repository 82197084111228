import { combineReducers } from 'redux';
import { sessionReducer } from 'redux-react-session';
import { connectRouter } from 'connected-react-router';

import loaderReducer from './loaderReducer';
import login from './loginReducer';
import logout from './logoutReducer';
import forget_password from './forgetPasswordReducer';
import change_password from './changePasswordReducer';
import reset_password from './resetPasswordReducer';
import new_password from './newPasswordReducer';
import patients from './patientsReducer';
import patient from './patientReducer';
import priscriptionlist from './priscriptionListReducer';
import pathologylist from './pathologyListReducer';
import radiologylist from './radiologyListReducer';
import prescriptiondetail from './prescriptionDetailReducer';
import addPatientCode from './addPatientCodeReducer';
import patientOtpReducer from './patientOtpReducer';
import registrationOtpReducer from './registrationOtpReducer';
import editProfileReducer from './editProfileReducer';
import certificate_list from './patientCertificateReducer';
import notifications from './notificationsReducer';
import other_prescription from './otherPrescriptionReducer';
import appointment from './appointmentReducer';
import historic_data_list from './patientHistoricDataReducer';
import patient_pre_reg from './preRegPatientReducer';
import uploaded_report_list from './patientUploadedReportListReducer';

export default history =>
  combineReducers({
    router: connectRouter(history),
    session: sessionReducer,
    loader: loaderReducer,
    login,
    logout,
    forget_password,
    change_password,
    reset_password,
    new_password,
    patients,
    patient,
    priscriptionlist,
    prescriptiondetail,
    addPatientCode,
    patientOtpReducer,
    editProfileReducer,
    registrationOtpReducer,
    pathologylist,
    radiologylist,
    certificate_list,
    notifications,
    other_prescription,
    appointment,
    historic_data_list,
    patient_pre_reg,
    uploaded_report_list
  });