import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import * as authAction from '../../actions/authAction';
import AuthService from '../../services/authService';
import Notification from '../../utils/notification';
import _ from 'lodash';
import { getDateFormat, addingCommaInobject, getDateTimeFormat} from '../../utils/helpers';
import ShowPoojaPoints from '../../components/ShowPoojaPoints';
import QRCode from 'qrcode.react';

class ProfileDetail extends React.Component {
	constructor(props) {
		super(props);

		const patientId = this.props.match.params.patientId;
		this.state = {
			patientId: patientId,
			qrCode: null,
			accessCode :""
		}
	}
	componentWillMount() {
		this.props.getPatientById(this.state.patientId);
		document.body.classList.add('body-bg-gray');
	}
	getInsurance = () => {
		let patient_detail = this.props.patient.successResponse;
		return patient_detail.insurance_type.join(', ')
	}

	showQrCode = () => {
		AuthService.generateQrCode(this.state.patientId).then(resp => {
			this.setState({
				qrCode: resp.data.code
			})
		}).catch(_err => {
			Notification.show('error', { 'message': 'Something went wrong!' })
		})
	}
	showAccessCode = () =>{
		AuthService.generateAccessCode(this.state.patientId).then(resp => {
			this.setState({
				accessCode: resp.data
			})
		}).catch(_err => {
			Notification.show('error', { 'message': 'Something went wrong!' })
		})
	}

	render() {
		let patient_detail = this.props.patient.successResponse;

		if (_.isEmpty(patient_detail)) {
			return (<></>);
		}

		return (
			<React.Fragment>
				<Helmet>
					<title>Profile Info</title>
				</Helmet>

				<section className="middle-wrapper">
					<div className="container">
						<div className="list-header">
							<Link to="/dashboard" className="white-bg"><span>&#8249;</span> User List</Link>
						</div>
						{patient_detail.profile_image && <div className="add-patient profile-detail-img">
							<img className="rounded-circle" src={patient_detail.profile_image.thumbnail_url} alt="profile" />
							<h4>{_.capitalize(patient_detail.first_name)} Profile</h4>
						</div>}
						<div className="title-wrapper pull-right">
							<Link to={"/patients/" + this.state.patientId + "/edit"} className="btn no-bg custom-btn"><i className="add-user-icon"></i>Edit Patient</Link>
							{/*<a className="btn no-bg generate-qr custom-btn" href="javascript:void(0);" type="button" onClick={this.showQrCode.bind(this, this.state.patientId)}>
								<i className="user-icon"></i>Generate QR
							</a>*/}
							<a className="btn no-bg custom-btn" href="javascript:void(0);" type="button" onClick={()=> this.showAccessCode(this, this.state.patientId)}>
								<i className="user-icon"></i>Generate Access Code
							</a>
						</div>

						<div className="profile-detail box clearfix">
							<div className="col-50">
								<div className="profile-title">
									<i className="patient-detail-icon"></i>
									<h4>Patient Info</h4>
								</div>
								<div className="patient-detail">
									<p><span>Name :</span> <span>{patient_detail.full_name}</span></p>
									<p><span>Father Name :</span> <span>{patient_detail.father_name}</span></p>
									<p><span>Mother Name :</span> <span>{patient_detail.mother_name}</span></p>
									<p><span>DOB :</span> <span>{getDateFormat(patient_detail.date_of_birth)}</span></p>
									<p><span>Place of Birth:</span> <span>{patient_detail.place_of_birth}</span></p>
									<p><span>Gender :</span> <span>{_.capitalize(patient_detail.gender)}</span></p>
									<p><span>Occupation :</span> <span>{patient_detail.occupation ? patient_detail.occupation : '--'}</span></p>
									<p><span>Blood Group :</span> <span>{patient_detail.blood_group ? patient_detail.blood_group : '--'}</span></p>
									<p><span>Height :</span> <span>{patient_detail.height}</span></p>
									<p><span>Insurance :</span> <span>{patient_detail.insurance_type && this.getInsurance()} </span> </p>
									<p><span>Allergy :</span> <span>{!_.isEmpty(patient_detail.allergy) ? addingCommaInobject(patient_detail.allergy) : '--'}</span></p>
									<p><span>Comorbidities :</span> <span>{!_.isEmpty(patient_detail.comorbidities) ? addingCommaInobject(patient_detail.comorbidities) : '--'}</span></p>
								</div>
							</div>
							<div className="col-50">
								<div className="profile-title">
									<i className="patient-contact-icon"></i>
									<h4>Contact Info</h4>
								</div>
								<div className="patient-detail">
									<p><span>Contact Number :</span> <span>{patient_detail.contact_number} </span></p>
									<p><span>Alternate Number/Landline Number :</span> <span>{patient_detail.alt_contact_number ? patient_detail.alt_contact_number : '--'}</span></p>
									<p><span>Email :</span> <span>{patient_detail.email || '--'}</span></p>
									<p><span>Address1:</span> <span>{patient_detail.address.address_line_1}</span></p>
									<p><span>Address2:</span> <span>{patient_detail.address.address_line_2}</span></p>
									<p><span>Address3:</span> <span>{patient_detail.address.address_line_3}</span></p>
									<p><span>City/Community:</span> <span>{patient_detail.address.city}</span></p>
									<p><span>Parish/State/Province:</span> <span>{patient_detail.address.parish}</span></p>
									<p><span>Zip Code:</span> <span>{patient_detail.address.pincode}</span></p>
									<p><span>Country:</span> <span>{patient_detail.address.country}</span></p>
									<p><span>Next of Kin :</span> <span>{patient_detail.kin_name ? patient_detail.kin_name : '--'} </span></p>
									<p><span>Relationship with Kin :</span> <span>{patient_detail.kin_relation ? patient_detail.kin_relation : '--'} </span></p>
									<p><span>Kin's Contact Number :</span> <span>{patient_detail.kin_number ? patient_detail.kin_number : '--'}</span></p>
									<p><span>Nearest Police Station :</span> <span>{patient_detail.nearest_police_station ? patient_detail.nearest_police_station : '--'}</span></p>
								</div>
							</div>
						</div>
					</div>
				</section>
				{patient_detail.is_loyalty_customer ?
					<ShowPoojaPoints patient={patient_detail} />
					: <></>}

				{this.state.qrCode && <div className="qrcode-container" >
					<div className="alert-content">
						<h4>QR Code</h4>
						<div className="qrcode-content">
							<QRCode value={this.state.qrCode} size={250} />
						</div>
						<p>
							Please download mobile application for accessing QR Code
						</p>
					</div>
				</div>}
				{this.state.accessCode && (<section className="middle-wrapper" style={{marginTop:"10px"}}>
					<div className="container"><div className="profile-detail box clearfix">
					<div className="col-30 w-100">
						<div className="profile-title">
							<h4>Access Code Details</h4>
						</div>
						<div className="patient-detail">
							<p><span>Patient Code :</span> <span>{this.state.accessCode.patient_code}</span></p>
							<p><span>Consent Code :</span> <span>{this.state.accessCode.consent_code}</span></p>
							<p><span>Expiry At :</span> <span>{getDateTimeFormat(this.state.accessCode.expiry_at)}</span></p>
						</div>
					</div>
					</div>
					</div>
				</section>)}
			</React.Fragment >);
	}
}

const mapStateToProps = (state) => ({
	user: state.session.user,
	authenticated: state.session.authenticated,
	patient: state.patient
});

const mapActionsToProps = ({
	getPatientById: authAction.getPatientById
})

export default connect(mapStateToProps, mapActionsToProps)(ProfileDetail);
