import React from 'react';
import { isNull } from 'lodash'

const DoctorInfo = ({ doctor, doctor_location_info }) => {
    return <div className="content-wrapper">
        <h6>Doctor Info</h6>
        <ul className="details-listing">
            <li>Name: <span>{doctor.full_name}</span></li>
            <li>Specialization: <span>{doctor.specialization}</span></li>
            <li>Licence Number: <span>{doctor.license_number}</span></li>
            <li>Email: <span>{doctor.email}</span></li>
        </ul>
        <ul className="details-listing">
            <li>Contact Number: <span>{doctor.contact_number ? doctor.contact_number : '--'}</span></li>
            <li>Address: <span>{(doctor_location_info && doctor_location_info.address) ? (isNull(doctor_location_info.address.address_line_1) ? '' : doctor_location_info.address.address_line_1) + ' ' + (isNull(doctor_location_info.address.address_line_2) ? '' : doctor_location_info.address.address_line_2) + ' ' + (isNull(doctor_location_info.address.address_line_3) ? '' : doctor_location_info.address.address_line_3) : ''}</span></li>
            <li>Community: <span>{(doctor_location_info && doctor_location_info.address) ? doctor_location_info.address.city : ''}</span></li>
            <li>Parish: <span>{(doctor_location_info && doctor_location_info.address) ? doctor_location_info.address.parish : ''}</span></li>
            <li>Pincode: <span>{(doctor_location_info && doctor_location_info.address) ? doctor_location_info.address.pincode : ''}</span></li>
        </ul>
    </div>
}
export default DoctorInfo