import React from 'react';
import { connect } from 'react-redux';
import * as authAction from '../../../actions/authAction';
import * as HELPER from '../../../utils/helpers';
import DataGrid from '../../../components/DataGrid';
import SimpleReactValidator from 'simple-react-validator';

class PathologyTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            patientId: '',
            tags: {
                test_name: "",
                date: "",
            },
            filter: {
                test_name: "",
                date: "",
            }
        };
        this.validator = new SimpleReactValidator();
    }

    columns = () => {
        return [{
            dataField: 'document_name',
            text: 'Report Name',
            style: { wordBreak: 'break-word' }
        }, {
            dataField: 'test_name',
            text: 'Test Name',
            style: { wordBreak: 'break-word' },
            formatter: (cell) => cell || '--'
        }, {
            formatter: this.dateFormatter,
            text: 'Date'
        }, {
            formatter: this.dateReceivedFormatter,
            text: 'Received Date'
        }, {
            formatter: this.actionFormatter,
            text: 'Actions'
        }]
    }

    actionFormatter = (cell, row) => {
        return (
            <a href="javascript:;" onClick={(e) => {e.preventDefault();this.props.documentDownload(row.document_id);}} className="icon"><i className="view-icon-icon"></i> View</a>
        );
    }

    dateFormatter = (cell, row) => {
        if (row) {
            return HELPER.getDateFormat(row.created_at)
        }
    }
    dateReceivedFormatter = (cell, row) => {
        if (row) {
            return HELPER.getDateFormat(row.order_date)
        }
    }

    handleTableChange = (type, { page, sizePerPage, sortField = "created_at", sortOrder = "desc" }) => {
        if (type === "search") {
            page = 1;
        }

        this.props.getPathologyList(this.props.patientId, { page, sizePerPage, sortBy: { sortField, sortOrder } });
    };

    componentDidMount() {
        if (this.props.patientId) {
            this.props.getPathologyList(this.props.patientId, {sortBy: { sortField: "created_at", sortOrder: "desc" }});
        }
    }

    handleChange = (e) => {
        const filter = { ...this.state.filter, [e.target.name]: e.target.value || "" };
        this.setState({ filter });
    }
    handleSubmit = (e) => {
        e.preventDefault();

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.setState({});
            return false;
        }

        const { test_name, date } = this.state.filter;

        this.setState({ tags: { test_name, date } });
        this.props.getPathologyList(this.props.patientId, {
            page: 1,
            sortBy: { sortField: "created_at", sortOrder: "desc" },
            filter: this.state.filter,
        });
    }
    resetSearch = (e) => {
        e.preventDefault();
        const resetFilter = {
            test_name: "",
            date: "",
        };
        this.setState({
            filter: resetFilter,
            tags: { test_name: "", date: "" }
        }, () => {
            if (typeof window.searchToggle === "function") {
                window.searchToggle();
            }
            this.props.getPathologyList(this.props.patientId, {  page: 1,
                sortBy: { sortField: "created_at", sortOrder: "desc" },filter: resetFilter });
        });
    };

    render() {
        const { successResponse } = this.props.pathologylist;
        let tags = this.state.tags;
        return (
        <>
        <style>
            {`
                .btn-custom {
                    min-width: 150px !important;
                    margin: 5px;
                    margin-top: 17px;
                    height: 38px;
                }
            `}
        </style>
        <div className="search-wrapper mt-1 p-2 mb-3 rounded bg-white">
            {/* Search Label */}
            {/* <label className="font-weight-bold mb-2 d-block text-primary">Search</label> */}

            {/* Tags Display */}
            {Object.keys(tags).length > 0 && (
                <div className="mb-2">
                    {Object.keys(tags).map((tag) => (
                        tags[tag] && (
                            <span key={tag} className="badge badge-info p-1 mr-2">
                                {tags[tag]}
                                <span className="ml-1 text-danger font-weight-bold" role="button" data-tag={tag} onClick={this.removeFilter}> × </span>
                            </span>
                        )
                    ))}
                </div>
            )}

            {/* Search Form */}
            <div className="search-container">
                <form onSubmit={this.handleSubmit}>
                    <div className="row g-2 align-items-center">
                        {/* Test Name Field */}
                        <div className="col-md-6">
                            <label className="form-label text-dark pb-1">Test Name</label>
                            <input
                                type="text"
                                className="form-control border rounded px-2 py-1"
                                value={this.state.filter.test_name}
                                onChange={this.handleChange}
                                name="test_name"
                                placeholder="Enter test name"
                            />
                        </div>

                        {/* Date Field */}
                        <div className="col-md-6">
                            <label className="form-label text-dark pb-1">Date</label>
                            <input
                                type="date"
                                className="form-control border rounded px-2 py-1"
                                value={this.state.filter.date}
                                onChange={this.handleChange}
                                name="date"
                            />
                        </div>

                        {/* Buttons */}
                        <div className="col-md-4 d-flex gap-5">
                            <button type="submit" className="btn btn-primary rounded px-2 py-1 w-auto btn-custom">
                                Search
                            </button>
                            <button type="button" onClick={this.resetSearch} className="btn btn-outline-secondary rounded px-1 py-1 btn-custom">
                                Reset
                            </button>                            
                        </div>
                    </div>
                </form>
            </div>
        </div>


        <DataGrid
            columns={this.columns()}
            data={successResponse.data}
            meta={successResponse.meta}
            handleTableChange={this.handleTableChange}
            noDataIndication="No Record Found" />
            </>
        )
    }
}
const mapStateToProps = (state) => ({
    user: state.session.user,
    authenticated: state.session.authenticated,
    pathologylist: state.pathologylist
});

const mapActionsToProps = ({
    getPathologyList: authAction.getPathologyList,
    documentDownload: authAction.documentDownload
})

export default connect(mapStateToProps, mapActionsToProps)(PathologyTable);
