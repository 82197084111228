import React from 'react';
import { Helmet } from 'react-helmet';

class ThankYou extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<React.Fragment>
				<Helmet>
					<title>Thank You</title>
				</Helmet>

				<section className="middle-wrapper contact-wrapper">
					<div className="container">
						<div className="profile-detail box">
								<div className="patient-detail pl-4 pr-4">
									<h3>Registration ID #{this.props.match.params.id}</h3>
									<p className="lead text-justify">Thanks a bunch for filling that out. It means a lot to us, just like you do! We really appreciate you giving us a moment of your time today. Thanks for being you.</p>
									<p>
										Having trouble? <a href="tel:+1 888-429-5765">Contact us</a>
									</p>
								</div>
						</div>
					</div>
				</section>

			</React.Fragment>);
	}
}

export default ThankYou;