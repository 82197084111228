import axios from 'axios';
import { apiRoute, getApiHeader } from '../utils/helpers';

class CommunityService {

    static getList() {
        const requestOptions = {
            headers: getApiHeader()
        };

        return axios.get(apiRoute('/v1/community-list'), requestOptions);
    }
}


export default CommunityService;
