export const allowUserRole = [
];

export const parish = {
    'Saint Andrew': 'Saint Andrew',
    'Kingston': 'Kingston',
    'Saint Thomas': 'Saint Thomas',
    'Portland': 'Portland',
    'Saint Mary': 'Saint Mary',
    'Saint Ann': 'Saint Ann',
    'Trelawny': 'Trelawny',
    'Saint James': 'Saint James',
    'Hanover': 'Hanover',
    'Westmoreland': 'Westmoreland',
    'Saint Elizabeth': 'Saint Elizabeth',
    'Manchester': 'Manchester',
    'Clarendon': 'Clarendon',
    'Saint Catherine': 'Saint Catherine',
};

export const DIMENSION = 'small';

export const INSURANCE = {
    "private-insurance-sagicore": 'Private Insurance Sagicor',
    "private-insurance-medicus": 'Private Insurance Medicus',
    "private-insurance-canopy": 'Private Insurance Canopy',
    'jadep': 'JADEP',
    'nhf': 'NHF',
    'goj': 'GOJ',
    'other': 'Other',
    'no': '--'
}

export const SPECIALIZATION = ['General Practitioner', 'Family Physician', 'Pediatrician', 'Cardiologist', 'Obstetrician/Gynecologist', 'Surgeon', 'Psychiatrist', 'Dermatologist', 'Orthopedic', 'Endocrinologist', 'Neurologist', 'Nephrologist', 'Gastroenterologist', 'Infectious Disease', 'Ophthalmologist', 'Otolaryngologist/ ENT', 'Pulmonologist', 'Radiologist', 'Oncologist', 'Anesthesiologist', 'Rheumatologist', 'Other'];

export const name_title = ['Dr.', 'Mr.', 'Mrs.', 'Ms.', 'Mast.'];

export const minor_name_title = ['Ms.', 'Mast.'];

export const BLOODGROUP = ['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-'];
