import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import * as authAction from '../../actions/authAction';
import _ from 'lodash';
import * as HELPERS from '../../utils/helpers';
import AuthService from '../../services/authService';
import Notification from '../../utils/notification';

class DeleteAccount extends React.Component {

    constructor(props) {
        super(props);
        this.props.getPatient();
    }

    getPatientData() {
        return this.props.patients.successResponse.data.map((data) => {
            return (<li className="grid-list-4">
                <div className="patient-title">
                    {data.profile_image ? <span className="title-sort"><img className="rounded-circle" src={data.profile_image.thumbnail_url} alt="profile" /></span> : <span className="title-sort"></span>}
                    <h6 className="title">{_.startCase(data.full_name)}</h6>
                </div>
                <div className="patient-detail">
                    <ul>
                        <li><span>Gender: </span><span className="dark">{_.upperFirst(data.gender)}</span></li>
                        <li><span>Date of Birth: </span><span className="dark">{HELPERS.getDateFormat(data.date_of_birth)}</span></li>
                    </ul>
                </div>
            </li>
            );
        })
    }

    deleteAccount = () => {
        AuthService.deleteAccount()
            .then(resp => {
                Notification.show('success', { message: "Account deleted successfully." });
                setTimeout(() => {
                    this.props.removeUserSession()
                }, 1000);
            })
            .catch(error => {
                if (error.response) {
                    Notification.show('error', error.response.data);
                }
            });
    }

    render() {
        return (<React.Fragment>
            <Helmet>
                <title>Dashboard</title>
            </Helmet>

            <section className="middle-wrapper">
                <div className="container">
                    <div className="title-wrapper">
                        <h4>Delete Account</h4>
                    </div>

                    <ul className="grid profile">
                        {!_.isEmpty(this.props.patients.successResponse) && !_.isEmpty(this.props.patients.successResponse.data) && this.getPatientData()}
                    </ul>

                    <div className="account-delete-box">
                        <div className='delete-text'>Deleting your account will delete all records under the account as well as your linked patient records. This action cannot be undone.</div>
                        <div className='delete-text font-weight-bold'>Confirm you want to delete your account.</div>
                        <div className="btn-group mt-4">
                            <button type="button" onClick={this.deleteAccount} className="btn btn-danger btn-sm" >Delete Account</button>
                            <Link to={"/dashboard"} className="btn btn-secondry ml-3">Cancel</Link>
                        </div>

                    </div>
                </div>
            </section>
        </React.Fragment>);
    }
}

const mapStateToProps = (state) => ({
    user: state.session.user,
    authenticated: state.session.authenticated,
    patients: state.patients
});

const mapActionsToProps = ({
    getPatient: authAction.getPatient,
    removeUserSession: authAction.removeUserSession,
})

export default connect(mapStateToProps, mapActionsToProps)(DeleteAccount);