import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import PrescriptionTable from './report-tables/prescription';
import PathologyTable from './report-tables/pathology';
import RadiologyTable from './report-tables/radiology';
import OtherPrescription from './report-tables/other-prescription';
import SelfUploadedMedicalReportTable from './report-tables/self-uploaded';
import * as authAction from '../../actions/authAction';
import SelectPage from '../../components/SelectPage';

class PatientPrescription extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            patientId: this.props.match.params.patientId
        }
    }

    tabChange = (e) => {
        let activeTab = e.target.getAttribute('data-tab');
        let allTabs = document.getElementsByClassName('tab-content');
        let allLinks = document.getElementsByClassName('tab-link');
        let newActiveTab = document.querySelector('#' + activeTab);
        for (let i = 0; i < allTabs.length; i++) {
            allTabs[i].classList.remove('active');
        }
        for (let j = 0; j < allTabs.length; j++) {
            allLinks[j].classList.remove('active');
        }
        newActiveTab.classList.add('active');
        e.target.classList.add('active');
    }

    componentDidMount() {
        document.body.classList.add('body-bg-gray');
        if (this.props.patient.patientId !== this.state.patientId) {
            this.props.getPatientById(this.state.patientId);
        }
    }

    render() {
        const { patientId } = this.state;
        let patient_detail = this.props.patient.successResponse;
        return (<React.Fragment>
            <Helmet>
                <title>Prescription</title>
            </Helmet>
            <section className="middle-wrapper">
                <div className="container">
                    <div className="list-header">
                        <Link to="/dashboard" className="white-bg"><span>&#8249;</span> User List</Link>
                        <span className="patient-name">{patient_detail.full_name}</span>
                        <SelectPage history={this.props.history} patientId={patientId} />
                    </div>

                    <section>
                        <div className="container prescription-tabs">
                            <ul className="tabs">
                                <li className="tab-link active" data-tab="tab-1" onClick={this.tabChange}>Prescription</li>
                                <li className="tab-link" data-tab="tab-2" onClick={this.tabChange}>Lab Report</li>
                                <li className="tab-link" data-tab="tab-3" onClick={this.tabChange}>Radiology Report</li>
                                <li className="tab-link" data-tab="tab-4" onClick={this.tabChange}>Others</li>
                                <li className="tab-link" data-tab="tab-5" onClick={this.tabChange}>Self Uploaded Reports</li>
                            </ul>
                            <div id="tab-1" className="tab-content active">
                                <div className="">
                                    <PrescriptionTable patientId={patientId}
                                        hideCheckbox={true} />
                                </div>
                            </div>
                            <div id="tab-2" className="tab-content">
                                <div className="">
                                    <PathologyTable patientId={patientId} />
                                </div>
                            </div>
                            <div id="tab-3" className="tab-content">
                                <div className="">
                                    <RadiologyTable patientId={patientId} />
                                </div>
                            </div>
                            <div id="tab-4" className="tab-content">
                                <div className="">
                                    <OtherPrescription patientId={patientId} />
                                </div>
                            </div>
                            <div id="tab-5" className="tab-content">
                                <div className="">
                                    <SelfUploadedMedicalReportTable patientId={patientId} />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
        </React.Fragment >);
    }
}

const mapStateToProps = (state) => ({
    user: state.session.user,
    authenticated: state.session.authenticated,
    patient: state.patient
});

const mapActionsToProps = ({
    getPatientById: authAction.getPatientById
})

export default connect(mapStateToProps, mapActionsToProps)(PatientPrescription);
