import React from 'react';
import AuthService from '../../services/authService';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { isEmpty, capitalize, filter } from 'lodash';
import { getDateFormat, addingCommaInobject } from '../../utils/helpers';
import { Link } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import * as Config from '../../config/index';
import Notification from '../../utils/notification';
import * as LoaderAction from '../../actions/loaderAction';
import * as authAction from '../../actions/authAction';
import CommunityService from '../../services/communityService';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';

class EditProfile extends React.Component {
    constructor(props) {
        super(props);
        const patientId = this.props.match.params.patientId;
        this.state = {
            patientId: patientId,
            patient_detail: '',
            address_line_1: '',
            address_line_2: '',
            address_line_3: '',
            email: '',
            kin_name: '',
            kin_relation: '',
            kin_number: '',
            nearest_police_station: '',
            occupation: '',
            disableSubmit: false,
            profilePicId: '',
            profilePicUrl: '',
            communities: [],
            selectedParisCommunity: [],
            countries: [],
        }

        this.validator = new SimpleReactValidator({});
        this.onFileChange = this.onFileChange.bind(this);
        this.getCommunity();
        this.loadCountries();
    }

    getCommunity = () => {
        CommunityService.getList().then(resp => {
            this.setState({
                communities: resp.data.data,
            }, () => {
                this.setSelectedParisCommunity(this.state.parish)
            })
        }).catch(error => {
            Notification.show('error', error.response.data)
        })
    }

    loadCountries = () => {
        AuthService.getCountryList().then(resp => {
            let countries = resp.data.map((items) => {
                return { 'label': items.name, 'value': items.name };
            })
            this.setState({
                countries
            });
        }).catch(error => {
            Notification.show('error', error.response.data)
        })
    }

    componentDidMount() {
        this.props.showLoader()
        AuthService.getPatientById(this.state.patientId).then(resp => {
            const { data } = resp
            this.setState({
                patient_detail: data,
                address_line_1: data.address.address_line_1 ? data.address.address_line_1 : '',
                address_line_2: data.address.address_line_2 ? data.address.address_line_2 : '',
                address_line_3: data.address.address_line_3 ? data.address.address_line_3 : '',
                email: data.email ? data.email : '',
                kin_name: data.kin_name ? data.kin_name : '',
                kin_relation: data.kin_relation ? data.kin_relation : '',
                kin_number: data.kin_number ? data.kin_number : '',
                nearest_police_station: data.nearest_police_station ? data.nearest_police_station : '',
                occupation: data.occupation ? data.occupation : '',
                parish: data.address.parish ? data.address.parish : '',
                pincode: data.address.pincode ? data.address.pincode : '',
                city: data.address.city ? data.address.city : '',
                country: data.address.country ? data.address.country : null,
                profilePicId: data.profile_image ? data.profile_image.image_id : '',
                profilePicUrl: data.profile_image ? data.profile_image.thumbnail_url : '',
            }, () => {
                this.setSelectedParisCommunity(data.address.parish ? data.address.parish : '')
            });
            this.props.hideLoader()
        });
        document.body.classList.add('body-bg-gray');
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })

        if (e.target.name === "parish" && this.state.country === "Jamaica") {
            this.setSelectedParisCommunity(e.target.value)
            this.setCommunity(null)
        }
    }

    submitHandler = (e) => {
        e.preventDefault();

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }
        const data = {
            address: {
                address_line_1: this.state.address_line_1,
                address_line_2: this.state.address_line_2 ? this.state.address_line_2 : null,
                address_line_3: this.state.address_line_3 ? this.state.address_line_3 : null,
                parish: this.state.parish ? this.state.parish : null,
                pincode: this.state.pincode ? this.state.pincode : null,
                city: this.state.city ? this.state.city : null,
                country: this.state.country ? this.state.country : null,
            },
            email: this.state.email ? this.state.email : null,
            kin_name: this.state.kin_name ? this.state.kin_name : null,
            kin_relation: this.state.kin_relation ? this.state.kin_relation : null,
            kin_number: this.state.kin_number ? this.state.kin_number : null,
            nearest_police_station: this.state.nearest_police_station ? this.state.nearest_police_station : null,
            occupation: this.state.occupation ? this.state.occupation : null,
        }
        this.props.showLoader()
        AuthService.updatePatientDetails(this.state.patientId, data).then(resp => {
            this.props.getPatientById(this.state.patientId);
            this.props.hideLoader();
            Notification.show('success', resp.data);
            this.props.history.push("/patients/" + this.state.patientId)
        }).catch(error => {
            this.props.hideLoader()
            Notification.show('error', error.response.data);
        })
    }

    onFileChange = event => {

        let file = event.target.files[0];
        let fileSize = file.size / 1024;
        if (fileSize > 5120) {
            Notification.show('error', { "message": "The file may not be greater than 5 MB." });
            return false;
        }
        this.setState({
            disableSubmit: true
        });
        const formData = new FormData();
        formData.append('file', file);
        AuthService.uploadProfilePicture(this.state.patientId, formData).then(resp => {
            this.setState({
                profilePicId: resp.data.image_id,
                profilePicUrl: resp.data.thumbnail_url,
                disableSubmit: false
            })
        }).catch(error => {
            this.setState({
                disableSubmit: false
            });
            console.log(error);
        })

    };

    deleteProfile = () => {
        this.props.showLoader()
        AuthService.deleteProfilePicture(this.state.patientId).then(resp => {
            this.setState({
                profilePicId: '',
                profilePicUrl: ''
            })
            this.props.hideLoader();
            Notification.show('success', resp.data);
        }).catch(error => {
            this.props.hideLoader();
            Notification.show('error', error.response.data)
        })
    }

    setSelectedParisCommunity = (parish) => {
        if (parish) {
            let selectedCommunities = filter(this.state.communities, (items) => items.parish_name == parish)
            selectedCommunities = selectedCommunities.map((items) => {
                return { 'label': items.community_name, 'value': items.community_name };
            })
            this.setState({
                selectedParisCommunity: selectedCommunities
            });
        } else {
            this.setState({
                selectedParisCommunity: []
            });
        }
    }

    setCommunity = (community) => {
        this.setState({ city: community ? community.value : '' });
    }

    setCountry = (country) => {
        this.setState({ country: country ? country.value : '' }, () => {
            this.setState({
                parish: "",
                city: "",
                pincode: ""
            })
        });
    }

    createCommunity = (community) => {
        let selectedParisCommunity = this.state.selectedParisCommunity;
        selectedParisCommunity.push({ label: community, value: community });
        this.setState({ city: community, selectedParisCommunity: selectedParisCommunity });
    }

    render() {
        let { patient_detail } = this.state;
        this.validator.purgeFields();
        return (
            <React.Fragment>
                <Helmet>
                    <title>Edit Profile</title>
                </Helmet>
                {!isEmpty(patient_detail) && <section className="middle-wrapper v-center">
                    <div className="container">
                        <div className="list-header">
                            <Link to="/dashboard" className="white-bg"><span>&#8249;</span> User List</Link>
                        </div>
                        <div className="profile-detail-wrapper clearfix">
                            <div className="image-wrapper">
                                {this.state.profilePicUrl ? <img src={this.state.profilePicUrl} className="rounded-circle" alt="profile" /> : <img src="../../images/teamwork.png" className="rounded-circle" alt="profile" />}
                                <span className="change-image-option">
                                    <span>&nbsp;
                                        <ul className="photo-change-option">
                                            {this.state.profilePicId && <li onClick={this.deleteProfile}><i className="remove-profile-pic"></i> Remove profile picture</li>}
                                            <li><label htmlFor="file-upload"><i className="update-profile-pic"></i>Update profile picture</label></li>
                                        </ul>
                                    </span>
                                    <input type="file" name="file" id="file-upload" onChange={this.onFileChange} accept=".png, .jpeg, .jpg" />
                                </span>
                            </div>
                            <div className="profile-detail-inner">
                                <h3>Personal Details</h3>
                                <div className="patient-detail">
                                    <p><span>Name :</span> <span>{patient_detail.full_name}</span></p>
                                    <p><span>Father Name :</span> <span>{patient_detail.father_name ? patient_detail.father_name : '--'}</span></p>
                                    <p><span>Mother Name :</span> <span>{patient_detail.mother_name ? patient_detail.mother_name : '--'}</span></p>
                                    <p><span>Date of Birth :</span> <span>{getDateFormat(patient_detail.date_of_birth)}</span></p>
                                    <p><span>Gender :</span> <span>{capitalize(patient_detail.gender)}</span></p>
                                    <p><span>Contact Number :</span> <span>{patient_detail.contact_number ? patient_detail.contact_number : '--'}</span></p>
                                    <p><span>Alternate Number/Landline Number :</span> <span>{patient_detail.alt_contact_number ? patient_detail.alt_contact_number : '--'}</span></p>
                                    <p><span>Place of Birth:</span> <span>{patient_detail.place_of_birth}</span></p>
                                    <p><span>Blood Group :</span> <span>{patient_detail.blood_group ? patient_detail.blood_group : '--'}</span></p>
                                    <p><span>Height :</span> <span>{patient_detail.height}</span></p>
                                    <p><span>Insurance Type:</span> <span>{addingCommaInobject(patient_detail.insurance_type) === "no" ? '--' : addingCommaInobject(patient_detail.insurance_type)}</span></p>
                                    <p><span>Allergy :</span> <span>{!isEmpty(patient_detail.allergy) ? addingCommaInobject(patient_detail.allergy) : '--'}</span></p>
                                    <p><span>Comorbidities :</span> <span>{!isEmpty(patient_detail.comorbidities) ? addingCommaInobject(patient_detail.comorbidities) : '--'}</span></p>
                                </div>
                            </div>
                        </div>
                        <div className="contact-detail-wrapper">
                            <h3>Contact Details</h3>
                            <div className="patient-detail">
                                <form>
                                    <div className="form-row">
                                        <div className="form-group col-md-12">
                                            <label htmlFor="email">Email*: </label>
                                            <input type="email" className="form-control" value={this.state.email} id="email" name="email" onChange={this.onChange} />
                                            {this.validator.message('email', this.state.email, 'required')}
                                        </div>
                                        <div className="form-group col-md-12">
                                            <label htmlFor="occupation">Occupation: </label>
                                            <input type="text" className="form-control" value={this.state.occupation} id="occupation" name="occupation" onChange={this.onChange} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="address_line_1">Address 1*: </label>
                                        <input type="text" className="form-control" value={this.state.address_line_1} id="address_line_1" name="address_line_1" onChange={this.onChange} />
                                        {this.validator.message('address_line_1', this.state.address_line_1, 'required|max:100')}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="address_line_2">Address 2: </label>
                                        <input type="text" className="form-control" value={this.state.address_line_2} id="address_line_2" name="address_line_2" onChange={this.onChange} />
                                        {this.validator.message('address_line_2', this.state.address_line_2, 'string|max:100')}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="address_line_3">Address 3: </label>
                                        <input type="text" className="form-control" value={this.state.address_line_3} id="address_line_3" name="address_line_3" onChange={this.onChange} />
                                        {this.validator.message('address_line_3', this.state.address_line_3, 'string|max:100')}
                                    </div>

                                    <div className="form-row">
                                        <div className="form-group col-md-8">
                                            <label htmlFor="Country">Country*: </label>
                                            <Select isClearable options={this.state.countries} id="country" value={this.state.country ? { value: this.state.country, label: this.state.country } : null} onChange={(newValue) => this.setCountry(newValue)} />
                                            {this.validator.message('country', this.state.country, 'required')}
                                        </div>

                                        <div className="form-group col-md-8">
                                            <label htmlFor="parish">Parish/State/Province*: </label>
                                            {
                                                this.state.country === "Jamaica" ?
                                                    <select className="form-control" value={this.state.parish} id="parish" name="parish" onChange={this.onChange}>
                                                        <option value="" disabled>Select Parish</option>
                                                        {Object.keys(Config.parish).map(function (key) {
                                                            return <option className="travelcompany-input" key={Config.parish[key] + '-' + key} value={key}>
                                                                {Config.parish[key]}
                                                            </option>;
                                                        })}
                                                    </select>
                                                    :
                                                    <input type="text" className="form-control" value={this.state.parish} id="parish" name="parish" onChange={this.onChange} />
                                            }
                                            {this.validator.message('Parish/state/province', this.state.parish, 'required')}
                                        </div>

                                        <div className="form-group col-md-8">
                                            <label htmlFor="community">City/Community*: </label>
                                            {
                                                this.state.country === "Jamaica" ?
                                                    <CreatableSelect isClearable options={this.state.selectedParisCommunity} id="community" value={{ value: this.state.city, label: this.state.city }} onChange={(newValue) => this.setCommunity(newValue)} onCreateOption={this.createCommunity} />
                                                    :
                                                    <input type="text" className="form-control" value={this.state.city} id="city" name="city" onChange={this.onChange} />
                                            }
                                            {this.validator.message('city/community', this.state.city, 'required' + (this.state.country === "Jamaica" ? '|alpha_num_dash_space' : ''))}
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-8">
                                            <label htmlFor="pincode">Zip Code: </label>
                                            <input type="text" className="form-control" value={this.state.pincode} id="pincode" name="pincode" onChange={this.onChange} />
                                            {this.validator.message('pincode', this.state.pincode, 'alpha_num')}
                                        </div>
                                        <div className="form-group col-md-8">
                                            <label htmlFor="kin_name">Next of Kin: </label>
                                            <input type="text" className="form-control" value={this.state.kin_name} id="kin_name" name="kin_name" onChange={this.onChange} />
                                        </div>
                                        <div className="form-group col-md-8">
                                            <label htmlFor="kin_relation">Relationship with Kin: </label>
                                            <input type="text" className="form-control" value={this.state.kin_relation} id="kin_relation" name="kin_relation" onChange={this.onChange} />
                                        </div>

                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-8">
                                            <label htmlFor="kin_number">Kin's Contact Number: </label>
                                            <input type="text" className="form-control" value={this.state.kin_number} id="kin_number" name="kin_number" onChange={this.onChange} />
                                            {this.validator.message('kin_number', this.state.kin_number, 'phone')}
                                        </div>
                                        <div className="form-group col-md-8">
                                            <label htmlFor="nearest_police_station">Nearest Police Station: </label>
                                            <input type="text" className="form-control" value={this.state.nearest_police_station} id="nearest_police_station" name="nearest_police_station" onChange={this.onChange} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="btn-group">
                            <button type="button" onClick={this.submitHandler} className="btn btn-primary btn-sm" disabled={this.state.disableSubmit ? 'disabled' : ''}>Submit</button>
                            <Link to={"/patients/" + this.state.patientId} className="btn btn-secondry">Cancel</Link>
                        </div>
                    </div>
                </section>}
            </React.Fragment >
        )
    }
}
const mapStateToProps = (state) => ({});

const mapActionsToProps = ({
    hideLoader: LoaderAction.loaderHide,
    showLoader: LoaderAction.loaderShow,
    getPatientById: authAction.getPatientById
})
export default connect(mapStateToProps, mapActionsToProps)(EditProfile)
