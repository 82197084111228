import React, { Component } from 'react'
import Notification from '../utils/notification';
import _ from 'lodash';
import SimpleReactValidator from 'simple-react-validator';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import * as Config from '../config';
import * as HELPER from '../utils/helpers';
import * as PreRegPatientAction from '../actions/preRegPatientAction';
import { connect } from 'react-redux';
import PreRegPatientService from '../services/preRegPatientService';
import CommunityService from '../services/communityService';
import CreatableSelect from 'react-select/creatable';
import AuthService from '../services/authService';
import Select from 'react-select';

class PreRegisterPatient extends Component {
    constructor(props) {
        super(props);

        const serviceProviderId = this.props.match.params.serviceProviderId;
        const userLocationId = this.props.match.params.userLocationId;
        this.state = {
            name_title: '',
            first_name: "",
            middle_name: "",
            last_name: "",
            father_name: "",
            mother_name: "",
            mother_maiden_name: "",
            date_of_birth: "",
            place_of_birth: "",
            gender: "",
            email: "",
            contact_number: "",
            alt_contact_number: "",
            blood_group: "",
            height: "",
            insurance_type: [],
            jadep_number: "",
            nhf_number: "",
            goj_number: "",
            sagicore_number: '',
            medicus_number: '',
            canopy_number: '',
            is_no: false,
            is_other: false,
            is_private_sagicore: false,
            is_private_medicus: false,
            is_private_canopy: false,
            is_jadep: false,
            is_nhf: false,
            is_goj: false,
            isNoChecked: false,
            isPrivateChecked: false,
            isJadepChecked: false,
            isNHFChecked: false,
            isGOJChecked: false,
            isJadepDisabled: true,
            address_line_1: "",
            address_line_2: "",
            address_line_3: "",
            parish: "",
            city: "",
            pincode: "",
            kin_name: '',
            kin_number: '',
            kin_relation: '',
            nearest_police_station: '',
            occupation: '',
            allergies: [],
            comorbidities: [],
            created_by_service_provider_id: serviceProviderId,
            created_at_user_location_id: userLocationId,
            disableSubmit: false,
            agree: '',
            profilePicId: '',
            profilePicUrl: '',
            communities: [],
            selectedParisCommunity: [],
            minor: 'no',
            countries: [],
            country: 'Jamaica',
        };

        this.validator = new SimpleReactValidator({
            validators: {
                insurance_type: {
                    message: 'Select atleast one insurance type or select No',
                    rule: (val) => {
                        return val.length > 0;
                    },
                    required: true
                }
            }
        });
        this.handleChange = this.handleChange.bind(this);
        this.handleInsuranceTypeChange = this.handleInsuranceTypeChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
        this.getCommunity();
        this.loadCountries();
    }

    getCommunity = () => {
        CommunityService.getList().then(resp => {
            this.setState({
                communities: resp.data.data,
            })
        }).catch(error => {
            Notification.show('error', error.response.data)
        })
    }

    loadCountries = () => {
        AuthService.getCountryList().then(resp => {
            let countries = resp.data.map((items) => {
                return { 'label': items.name, 'value': items.name };
            })
            this.setState({
                countries
            });
        }).catch(error => {
            Notification.show('error', error.response.data)
        })
    }

    setCountry = (country) => {
        this.setState({ country: country ? country.value : '' }, () => {
            this.setState({
                parish: "",
                city: "",
                pincode: ""
            })
        });
    }

    handleCheckChange(e) {
        if (e.target.checked) {
            this.setState({ [e.target.name]: e.target.value });
        } else {
            this.setState({ [e.target.name]: '' });
        }
    }

    handleChange(e) {
        if (e.target.name === 'date_of_birth') {
            const patientAge = moment().diff(e.target.value, 'years');

            if (patientAge >= 60) {
                this.setState({ [e.target.name]: e.target.value, isJadepDisabled: false });
            } else {
                this.setState({
                    [e.target.name]: e.target.value,
                    isJadepDisabled: true,
                    jadep_number: '',
                    is_jadep: false
                });
            }
            return true;
        }

        if (e.target.name === "parish" && this.state.country === "Jamaica") {
            this.setSelectedParisCommunity(e.target.value)
            this.setCommunity(null)
        }

        this.setState({ [e.target.name]: e.target.value });
    }

    handleTagsChange = (e) => {
        let value = e.target.value ? (e.target.value).split(",") : [];
        this.setState({ [e.target.name]: value });
    }

    handleInsuranceTypeChange(e) {
        let insurance_type = this.state.insurance_type;

        const { is_no, is_private_sagicore, is_private_medicus, is_private_canopy, is_other } = this.state;

        if (e.target.checked) {
            insurance_type.push(e.target.value);
        } else {
            if (e.target.value === 'jadep') {
                this.setState({
                    jadep_number: ''
                });
            }
            if (e.target.value === 'nhf') {
                this.setState({
                    nhf_number: ''
                });
            }
            if (e.target.value === 'goj') {
                this.setState({
                    goj_number: ''
                });
            }
            if (e.target.value === 'private-insurance-sagicore') {
                this.setState({
                    private_insurance_sagicore_number: ''
                });
            }
            if (e.target.value === 'private-insurance-medicus') {
                this.setState({
                    private_insurance_medicus_number: ''
                });
            }
            if (e.target.value === 'private-insurance-canopy') {
                this.setState({
                    private_insurance_canopy_number: ''
                });
            }
            _.pull(insurance_type, e.target.value);
        }

        if (e.target.value === 'no') {
            if (e.target.checked) {
                insurance_type = ["no"];
            }
            this.setState({
                is_no: !is_no,
                is_private_sagicore: e.target.unchecked,
                is_private_medicus: e.target.unchecked,
                is_private_canopy: e.target.unchecked,
                is_jadep: e.target.unchecked,
                is_nhf: e.target.unchecked,
                is_goj: e.target.unchecked,
                is_other: e.target.unchecked,
                jadep_number: "",
                nhf_number: "",
                goj_number: "",
                private_insurance_sagicore_number: "",
                private_insurance_medicus_number: "",
                private_insurance_canopy_number: "",
            });
        } else {
            _.pull(insurance_type, "no");
        }

        if (e.target.value === 'private-insurance-sagicore') {
            this.setState({
                is_private_sagicore: !is_private_sagicore,
                is_no: false
            });
        }

        if (e.target.value === 'other') {
            this.setState({
                is_other: !is_other,
                is_no: false
            });
        }

        if (e.target.value === 'private-insurance-medicus') {
            this.setState({
                is_private_medicus: !is_private_medicus,
                is_no: false
            });
        }

        if (e.target.value === 'private-insurance-canopy') {
            this.setState({
                is_private_canopy: !is_private_canopy,
                is_no: false
            });
        }

        if (e.target.value === 'jadep') {
            this.setState({
                is_no: false,
                is_jadep: e.target.checked
            });
        }

        if (e.target.value === 'nhf') {
            this.setState({
                is_no: false,
                is_nhf: e.target.checked
            });
        }

        if (e.target.value === 'goj') {
            this.setState({
                is_no: false,
                is_goj: e.target.checked
            });
        }

        this.setState({ insurance_type: insurance_type });
    }

    handleSubmit(e) {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        let data = {
            name_title: this.state.name_title,
            first_name: this.state.first_name,
            middle_name: this.state.middle_name,
            last_name: this.state.last_name,
            father_name: this.state.father_name,
            mother_name: this.state.mother_name,
            maiden_name: this.state.mother_maiden_name,
            date_of_birth: this.state.date_of_birth,
            place_of_birth: this.state.place_of_birth,
            gender: this.state.gender,
            email: this.state.email,
            contact_number: this.state.contact_number,
            alt_contact_number: this.state.alt_contact_number,
            blood_group: this.state.blood_group,
            insurance_type: this.state.insurance_type,
            jadep_number: this.state.jadep_number,
            nhf_number: this.state.nhf_number,
            goj_number: this.state.goj_number,
            address: {
                address_line_1: this.state.address_line_1,
                address_line_2: this.state.address_line_2,
                address_line_3: this.state.address_line_3,
                city: this.state.city,
                country: this.state.country,
                pincode: this.state.pincode,
                parish: this.state.parish
            },
            allergies: this.state.allergies,
            comorbidities: this.state.comorbidities,
            height: this.state.height,
            kin_name: this.state.kin_name ? this.state.kin_name : '',
            kin_number: this.state.kin_number ? this.state.kin_number : '',
            kin_relation: this.state.kin_relation ? this.state.kin_relation : '',
            nearest_police_station: this.state.nearest_police_station ? this.state.nearest_police_station : '',
            occupation: this.state.occupation ? this.state.occupation : '',
            sagicore_number: this.state.private_insurance_sagicore_number ? this.state.private_insurance_sagicore_number : '',
            medicus_number: this.state.private_insurance_medicus_number ? this.state.private_insurance_medicus_number : '',
            canopy_number: this.state.private_insurance_canopy_number ? this.state.private_insurance_canopy_number : '',
            service_provider_id: this.props.match.params.serviceProviderId,
            user_location_id: this.props.match.params.userLocationId,
            profile_picture_id: this.state.profilePicId ? this.state.profilePicId : '',
            profile_picture_url: this.state.profilePicUrl ? this.state.profilePicUrl : '',
            is_no: this.state.is_no ? this.state.is_no : false,
            is_other: this.state.is_other ? this.state.is_other : false,
            is_private_sagicore: this.state.is_private_sagicore ? this.state.is_private_sagicore : false,
            is_private_medicus: this.state.is_private_medicus ? this.state.is_private_medicus : false,
            is_private_canopy: this.state.is_private_canopy ? this.state.is_private_canopy : false,
            is_jadep: this.state.is_jadep ? this.state.is_jadep : false,
            is_nhf: this.state.is_nhf ? this.state.is_nhf : false,
            is_goj: this.state.is_goj ? this.state.is_goj : false,
            minor: this.state.minor == 'yes' ? true : false
        };
        this.props.addPreRegPatient(data, this.props.history);
    }

    // On file select upload the file
    onFileChange = event => {

        let file = event.target.files[0];
        let fileSize = file.size / 1024;
        if (fileSize > 5120) {
            Notification.show('error', { "message": "The file may not be greater than 5 MB." });
            return false;
        }
        this.setState({
            disableSubmit: true
        });
        const formData = new FormData();
        formData.append('file', file);
        PreRegPatientService.uploadProfilePicture(formData).then(resp => {
            this.setState({
                profilePicId: resp.data.image_id,
                profilePicUrl: resp.data.thumbnail_url,
                disableSubmit: false
            })
        }).catch(error => {
            this.setState({
                disableSubmit: false
            });
            Notification.show('error', error.response.data)
        })
    };

    deleteProfile = () => {
        this.setState({
            disableSubmit: true
        });
        PreRegPatientService.deleteProfilePicture(this.state.profilePicId).then(resp => {
            this.setState({
                profilePicId: '',
                profilePicUrl: '',
                disableSubmit: false
            })
        }).catch(error => {
            Notification.show('error', error.response.data)
        })
    }

    setSelectedParisCommunity = (parish) => {
        if (parish) {
            let selectedCommunities = _.filter(this.state.communities, (items) => items.parish_name == parish)
            selectedCommunities = selectedCommunities.map((items) => {
                return { 'label': items.community_name, 'value': items.community_name };
            })
            this.setState({
                selectedParisCommunity: selectedCommunities
            });
        } else {
            this.setState({
                selectedParisCommunity: []
            });
        }
    }

    setCommunity = (community) => {
        this.setState({ city: community ? community.value : '' });
    }

    createCommunity = (community) => {
        let selectedParisCommunity = this.state.selectedParisCommunity;
        selectedParisCommunity.push({ label: community, value: community });
        this.setState({ city: community, selectedParisCommunity: selectedParisCommunity });
    }

  render() {
    this.validator.purgeFields();
    const { is_no, is_private_sagicore, is_private_medicus, is_private_canopy, is_jadep, is_nhf, is_goj, is_other } = this.state;
    return (
      <div>
            <Helmet>
                <title>Add New Patients</title>
            </Helmet>
            <div className="col-md-24 row m-auto">
                <div className="col-md-24">
                    <h3 className="page-title mt-3 mb-3 text-center">Pre Registered Patient</h3>
                    <div className="content-wrapper edit-patient">
                        <form onSubmit={this.handleSubmit}>
                        <div className="row">

                            <div className="col-sm-3">
                                <fieldset className="form-group">
                                    <label htmlFor="minoar">Minor</label>
                                    <select className="form-control" value={this.state.minor} id="minor" name="minor" onChange={e=>this.handleChange(e)}>
                                        <option value='no'>NO</option>
                                        <option value='yes'>YES</option>
                                    </select>
                                </fieldset>
                            </div>

                            <div className="col-sm-3">
                                <fieldset className="form-group">
                                    <label htmlFor="name_title">Title*</label>
                                    <select className="form-control" value={this.state.name_title} id="name_title" name="name_title" onChange={this.handleChange}>
                                        <option value="" disabled>Select Title</option>
                                        {this.state.minor == "no" && Config.name_title.map(function (key) {
                                            return <option value={key}>
                                                {key}
                                            </option>;
                                        })}

                                        {this.state.minor == "yes" && Config.minor_name_title.map(function (key) {
                                            return <option value={key}>
                                                {key}
                                            </option>;
                                        })}
                                    </select>
                                    {this.validator.message('name_title', this.state.name_title, 'required')}
                                </fieldset>
                            </div>

                            <div className="col-sm-6">
                                <fieldset className="form-group">
                                    <label htmlFor="first_name">First Name*</label>
                                    <input type="text" className="form-control" value={this.state.first_name} id="first_name" name="first_name" onChange={this.handleChange} />
                                    {this.validator.message('first_name', this.state.first_name, ['required', 'string', { regex: HELPER.personNameRegex() }])}
                                </fieldset>
                            </div>

                            <div className="col-sm-6">
                                <fieldset className="form-group">
                                    <label htmlFor="middle_name">Middle Name</label>
                                    <input type="text" className="form-control" value={this.state.middle_name} id="middle_name" name="middle_name" onChange={this.handleChange} />
                                    {this.validator.message('middle_name', this.state.middle_name, ['string', { regex: HELPER.personNameRegex() }])}
                                </fieldset>
                            </div>

                            <div className="col-sm-6">
                                <fieldset className="form-group">
                                    <label htmlFor="last_name">Last Name*</label>
                                    <input type="text" className="form-control" value={this.state.last_name} id="last_name" name="last_name" onChange={this.handleChange} />
                                    {this.validator.message('last_name', this.state.last_name, ['required', 'string', { regex: HELPER.personNameRegex() }])}
                                </fieldset>
                            </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="father_name">Father Name</label>
                                        <input type="text" className="form-control" value={this.state.father_name} id="father_name" name="father_name" onChange={this.handleChange} />
                                        {this.validator.message('father_name', this.state.father_name, ['string', { regex: HELPER.personNameRegex() }])}
                                    </fieldset>
                                </div>

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="mother_name">Mother Name</label>
                                        <input type="text" className="form-control" value={this.state.mother_name} id="mother_name" name="mother_name" onChange={this.handleChange} />
                                        {this.validator.message('mother_name', this.state.mother_name, ['string', { regex: HELPER.personNameRegex() }])}
                                    </fieldset>
                                </div>

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="mother_maiden_name">Mother's Maiden Name*</label>
                                        <input type="text" className="form-control" value={this.state.mother_maiden_name} id="mother_maiden_name" name="mother_maiden_name" onChange={this.handleChange} />
                                        {this.validator.message('mother_maiden_name', this.state.mother_maiden_name, ['required', 'string', { regex: HELPER.personNameRegex() }])}
                                    </fieldset>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="gender">Gender*</label>
                                        <select className="form-control" id="gender" name="gender" value={this.state.gender} onChange={this.handleChange}>
                                            <option value="">Select Gender</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                            <option value="other">Other</option>
                                        </select>
                                        {this.validator.message('gender', this.state.gender, 'required')}
                                    </fieldset>
                                </div>

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="date_of_birth">Date Of Birth*</label>
                                        <input type="date" className="form-control" value={this.state.date_of_birth} id="date_of_birth" name="date_of_birth" onChange={this.handleChange} max={moment().format('YYYY-MM-DD')} />
                                        {this.validator.message('date_of_birth', this.state.date_of_birth, 'required')}
                                    </fieldset>
                                </div>

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="place_of_birth">Place Of Birth*</label>
                                        <input type="text" className="form-control" value={this.state.place_of_birth} id="place_of_birth" name="place_of_birth" onChange={this.handleChange} />
                                        {this.validator.message('place_of_birth', this.state.place_of_birth, 'required')}
                                    </fieldset>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="email">Email</label>
                                        <input type="text" className="form-control" value={this.state.email} id="email" name="email" onChange={this.handleChange} />
                                        {this.validator.message('email', this.state.email, 'email')}
                                    </fieldset>
                                </div>

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="contact_number">Contact Number*</label>
                                        <input type="text" className="form-control" value={this.state.contact_number} id="contact_number" name="contact_number" onChange={this.handleChange} />
                                        {this.validator.message('contact_number', this.state.contact_number, 'required|phone|max:10')}
                                    </fieldset>
                                </div>

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="alt_contact_number">Alternate Number/Landline Number</label>
                                        <input type="text" className="form-control" value={this.state.alt_contact_number} id="alt_contact_number" name="alt_contact_number" onChange={this.handleChange} />
                                        {this.validator.message('alt_contact_number', this.state.alt_contact_number, 'phone|max:10')}
                                    </fieldset>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="blood_group">Blood Group</label>
                                        <select className="form-control" value={this.state.blood_group} id="blood_group" name="blood_group" onChange={this.handleChange}>
                                            <option value="" disabled>Unknown</option>
                                            {Object.keys(Config.BLOODGROUP).map(function (key) {
                                                return <option className="travelcompany-input" value={Config.BLOODGROUP[key]}>
                                                    {Config.BLOODGROUP[key]}
                                                </option>;
                                            })}
                                        </select>
                                        {this.validator.message('blood_group', this.state.blood_group, 'string')}
                                    </fieldset>
                                </div>

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="height">Height</label>
                                        <input type="number" name="height" id="height" className="form-control" value={this.state.height ? parseFloat(this.state.height) : ""} onChange={this.handleChange} min="0" autoComplete="off" placeholder="in cm" />
                                        {this.validator.message('height', this.state.height, 'numeric|min:0,num|max:300,num')}
                                    </fieldset>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-24">
                                    <fieldset className="form-group">
                                        <label htmlFor="insurance_type">Insurance Type*</label>
                                        <div className="checkbox-option-list">
                                            <div className="form-check form-check-inline">
                                                <input type="checkbox" id={'insurance_type-no'} name="insurance_type" value="no" onChange={this.handleInsuranceTypeChange} checked={is_no} />
                                                <label htmlFor={'insurance_type-no'}>No</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input type="checkbox" id={'insurance_type-jadep'} name="insurance_type" value="jadep" disabled={this.state.isJadepDisabled} onChange={this.handleInsuranceTypeChange} checked={is_jadep} />
                                                <label htmlFor={'insurance_type-jadep'}>JADEP</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input type="checkbox" id={'insurance_type-nhf'} name="insurance_type" value="nhf" onChange={this.handleInsuranceTypeChange} checked={is_nhf} />
                                                <label htmlFor={'insurance_type-nhf'}>NHF</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input type="checkbox" id={'insurance_type-goj'} name="insurance_type" value="goj" onChange={this.handleInsuranceTypeChange} checked={is_goj} />
                                                <label htmlFor={'insurance_type-goj'}>GOJ</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input type="checkbox" id={'insurance_type-private-insurance-sagicore'} name="insurance_type" value="private-insurance-sagicore" onChange={this.handleInsuranceTypeChange} checked={is_private_sagicore} />
                                                <label htmlFor={'insurance_type-private-insurance-sagicore'}>Private Insurance Sagicor</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input type="checkbox" id={'insurance_type-private-insurance-medicus'} name="insurance_type" value="private-insurance-medicus" onChange={this.handleInsuranceTypeChange} checked={is_private_medicus} />
                                                <label htmlFor={'insurance_type-private-insurance-medicus'}>Private Insurance Medicus</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input type="checkbox" id={'insurance_type-private-insurance-canopy'} name="insurance_type" value="private-insurance-canopy" onChange={this.handleInsuranceTypeChange} checked={is_private_canopy} />
                                                <label htmlFor={'insurance_type-private-insurance-canopy'}>Private Insurance Canopy</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input type="checkbox" id={'insurance_type-other'} name="insurance_type" value="other" onChange={this.handleInsuranceTypeChange} checked={is_other} />
                                                <label htmlFor={'insurance_type-other'}>Other</label>
                                            </div>
                                        </div>
                                        {this.validator.message('insurance_type', this.state.insurance_type, 'insurance_type')}
                                    </fieldset>
                                </div>
                            </div>
                            <div className="row">
                                {this.state.is_jadep && <div className="col-sm-6">
                                    <fieldset className="form-group">
                                        <label htmlFor="jadep_number">Jadep Number*</label>
                                        <input type="text" className="form-control" value={this.state.jadep_number} id="jadep_number" name="jadep_number" onChange={this.handleChange} />
                                        {this.validator.message('jadep_number', this.state.jadep_number, 'required|string|max:100')}
                                    </fieldset>
                                </div>}

                                {this.state.is_nhf && <div className="col-sm-6">
                                    <fieldset className="form-group">
                                        <label htmlFor="nhf_number">NHF Number*</label>
                                        <input type="text" className="form-control" value={this.state.nhf_number} id="nhf_number" name="nhf_number" onChange={this.handleChange} />
                                        {this.validator.message('nhf_number', this.state.nhf_number, 'required|string|max:100')}
                                    </fieldset>
                                </div>}

                                {this.state.is_goj && <div className="col-sm-6">
                                    <fieldset className="form-group">
                                        <label htmlFor="goj_number">GOJ Number*</label>
                                        <input type="text" className="form-control" value={this.state.goj_number} id="goj_number" name="goj_number" onChange={this.handleChange} />
                                        {this.validator.message('goj_number', this.state.goj_number, 'required|string|max:100')}
                                    </fieldset>
                                </div>}

                                {this.state.is_private_sagicore && <div className="col-sm-6">
                                    <fieldset className="form-group">
                                        <label htmlFor="goj_number">Sagicore Number*</label>
                                        <input type="text" className="form-control" value={this.state.private_insurance_sagicore_number} id="sagicore_number" name="private_insurance_sagicore_number" onChange={this.handleChange} />
                                        {this.validator.message('private_insurance_sagicore_number', this.state.private_insurance_sagicore_number, 'required|string|max:100')}
                                    </fieldset>
                                </div>}

                                {this.state.is_private_medicus && <div className="col-sm-6">
                                    <fieldset className="form-group">
                                        <label htmlFor="goj_number">Medicus Number*</label>
                                        <input type="text" className="form-control" value={this.state.private_insurance_medicus_number} id="medicus_number" name="private_insurance_medicus_number" onChange={this.handleChange} />
                                        {this.validator.message('private_insurance_medicus_number', this.state.private_insurance_medicus_number, 'required|string|max:100')}
                                    </fieldset>
                                </div>}

                                {this.state.is_private_canopy && <div className="col-sm-6">
                                    <fieldset className="form-group">
                                        <label htmlFor="goj_number">Canopy Number*</label>
                                        <input type="text" className="form-control" value={this.state.private_insurance_canopy_number} id="canopy_number" name="private_insurance_canopy_number" onChange={this.handleChange} />
                                        {this.validator.message('private_insurance_canopy_number', this.state.private_insurance_canopy_number, 'required|string|max:100')}
                                    </fieldset>
                                </div>}
                            </div>

                            <div className="row">
                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="address_line_1">Address 1*</label>
                                        <textarea maxLength="255" type="text" rows="2" className="form-control" value={this.state.address_line_1} id="address_line_1" name="address_line_1" onChange={this.handleChange} />
                                        {this.validator.message('address_line_1', this.state.address_line_1, 'required|string|max:100')}
                                    </fieldset>
                                </div>

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="address_line_2">Address 2</label>
                                        <textarea maxLength="255" type="text" className="form-control" value={this.state.address_line_2} rows="2" id="address_line_2" name="address_line_2" onChange={this.handleChange} />
                                        {this.validator.message('address_line_2', this.state.address_line_2, 'string|max:100')}
                                    </fieldset>
                                </div>

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="address_line_3">Address 3</label>
                                        <textarea type="text" maxLength="255" className="form-control" value={this.state.address_line_3} rows="2" id="address_line_3" name="address_line_3" onChange={this.handleChange} />
                                        {this.validator.message('address_line_3', this.state.address_line_3, 'string|max:100')}
                                    </fieldset>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-md-8">
                                    <label htmlFor="Country">Country*: </label>
                                    <Select isClearable options={this.state.countries} id="country" value={this.state.country ? { value: this.state.country, label: this.state.country } : null} onChange={(newValue) => this.setCountry(newValue)} />
                                    {this.validator.message('country', this.state.country, 'required')}
                                </div>
                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="parish">Parish*</label>
                                        {
                                            this.state.country === "Jamaica" ?
                                            <select className="form-control" value={this.state.parish} id="parish" name="parish" onChange={this.handleChange}>
                                                <option value="" disabled>Select Parish</option>
                                                {Object.keys(Config.parish).map(function (key) {
                                                    return <option className="travelcompany-input" value={key}>
                                                        {Config.parish[key]}
                                                    </option>;
                                                })}
                                            </select>
                                            :
                                            <input type="text" className="form-control" value={this.state.parish} id="parish" name="parish" onChange={this.handleChange} />
                                        }
                                        {this.validator.message('parish', this.state.parish, 'required')}
                                    </fieldset>
                                </div>

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="community">Community*</label>
                                        {
                                            this.state.country === "Jamaica" ?
                                            <CreatableSelect isClearable options={this.state.selectedParisCommunity} id="community" value={{ value: this.state.city, label: this.state.city }} onChange={(newValue) => this.setCommunity(newValue)} onCreateOption={this.createCommunity} />
                                            :
                                            <input type="text" className="form-control" value={this.state.city} id="city" name="city" onChange={this.handleChange} />
                                        }
                                        {this.validator.message('community', this.state.city, 'required|string|max:100')}
                                    </fieldset>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="pincode">Zip Code</label>
                                        <input type="text" className="form-control" value={this.state.pincode} id="pincode" name="pincode" onChange={this.handleChange} />
                                        {this.validator.message('pincode', this.state.pincode, 'alpha_num|max:7')}
                                    </fieldset>
                                </div>

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="kin_name">Next of Kin</label>
                                        <input type="text" className="form-control" value={this.state.kin_name} id="kin_name" name="kin_name" onChange={this.handleChange} />
                                        {this.validator.message('kin_name', this.state.kin_name, 'alpha_space')}
                                    </fieldset>
                                </div>

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="kin_relation">Relationship with Kin</label>
                                        <input type="text" className="form-control" value={this.state.kin_relation} id="kin_relation" name="kin_relation" onChange={this.handleChange} />
                                        {this.validator.message('kin_relation', this.state.kin_relation, 'alpha_space')}
                                    </fieldset>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="kin_number">Kin's Contact Number</label>
                                        <input type="text" className="form-control" value={this.state.kin_number} id="kin_number" name="kin_number" onChange={this.handleChange} />
                                        {this.validator.message('kin_number', this.state.kin_number, 'phone|max:10')}
                                    </fieldset>
                                </div>

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="nearest_police_station">Nearest Police Station</label>
                                        <input type="text" className="form-control" value={this.state.nearest_police_station} id="nearest_police_station" name="nearest_police_station" onChange={this.handleChange} />
                                    </fieldset>
                                </div>

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="occupation">Occupation</label>
                                        <input type="text" className="form-control" value={this.state.occupation} id="occupation" name="occupation" onChange={this.handleChange} />
                                    </fieldset>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="allergies">Any Known Allergies</label>
                                        <textarea className="form-control" id="allergies" name="allergies" value={this.state.allergies} onChange={this.handleTagsChange} placeholder="Type allergies"></textarea>
                                    </fieldset>
                                </div>

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="comorbidities">Comorbidities</label>
                                        <textarea className="form-control" id="comorbidities" name="comorbidities" value={this.state.comorbidities} onChange={this.handleTagsChange} placeholder="Type comorbidities"></textarea>
                                    </fieldset>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="profile_pic">Profile Picture</label>
                                        {_.isEmpty(this.state.profilePicUrl) && <div className="profile-pic">
                                            <img src="/images/user.svg" alt="" />
                                        </div>}
                                        {!_.isEmpty(this.state.profilePicUrl) && <div className="profile-pic">
                                            <img src={this.state.profilePicUrl} alt="" />
                                        </div>}

                                        <input className="hidden" id="profile-pic-upload" type="file" name="file" onChange={this.onFileChange} accept='.jpeg,.jpg,.png' />
                                        {_.isEmpty(this.state.profilePicUrl) && <label htmlFor="profile-pic-upload" className="profile-pic-upload ml-2">Choose File</label>}

                                        {!_.isEmpty(this.state.profilePicUrl) && <>
                                            <button type="button" className="btn btn-secondry profile-pic-btn mb-0 button-size" onClick={this.deleteProfile}>Remove</button>
                                            <label htmlFor="profile-pic-upload" className="btn btn-secondry profile-pic-btn mb-0">Change Image</label>
                                        </>}
                                    </fieldset>
                                </div>
                            </div>
                            <div className="form-group agree">
                                    <input type="checkbox" id="agree" name="agree" value="agree" onChange={this.handleCheckChange} />
                                    <label htmlFor="agree">I agree to all rights reserved. <a href="https://patient.daisy-health.com/privacy-policy.html" target="_blank">Privacy Policy</a> and <a href="https://patient.daisy-health.com/terms-conditions.html" target="_blank">Terms & Conditions </a></label>
                                    {this.validator.message('agree', this.state.agree, 'required')}
                            </div>
                            <div className="row">
                                <div className="col-sm-24">
                                    <button type="button" className="btn btn-primary" disabled={this.state.disableSubmit} onClick={this.handleSubmit}>Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div >
    )
  }
}

const mapStateToProps = state => ({
    // patient_list: state.patient_list,
    selected_user_profile: '',
});

const mapActionsToProps = ({
    addPreRegPatient: PreRegPatientAction.add,
})

export default connect(mapStateToProps,mapActionsToProps)(PreRegisterPatient)