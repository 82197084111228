import React from 'react';
import { connect } from 'react-redux';
import * as authAction from '../../../actions/authAction';
import AuthService from '../../../services/authService';
import * as HELPER from '../../../utils/helpers';
import DataGrid from '../../../components/DataGrid';
import { Modal, Form } from 'react-bootstrap';
import * as loaderAction from '../../../actions/loaderAction';
import Notification from '../../../utils/notification';
import SimpleReactValidator from 'simple-react-validator';

class SelfUploadedMedicalReportTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            specializationList: "",
            selectedSpecialization: '',
            doctorList: '',
            selectedDoctor: '',
            selectedReportId: null,
            showModal: false,
            showUploadModal: false,
            showDeleteModal: false,
            reportName: '',
            reportFile: null,
        };

        this.validator = new SimpleReactValidator();
    }

    componentDidMount() {
        this.props.getPatientUploadedReportList(this.props.patientId, {});

        this.props.loaderShow();
        AuthService.getMasters('doctor-specialization').then(resp => {
            this.setState({
                specializationList: resp.data.data
            })
            this.props.loaderHide();
        }).catch(_err => {
            this.props.loaderHide();
            Notification.show('error', { 'message': 'Something went wrong!' })
        })
    }

    columns = () => {
        return [{
            dataField: 'report_name',
            text: 'Report Name',
            style: { wordBreak: 'break-word' }
        }, {
            dataField: 'document_name',
            text: 'File Name',
            style: { wordBreak: 'break-word' },
            formatter: (cell) => cell || '--'
        }, {
            formatter: this.dateFormatter,
            text: 'Date'
        }, {
            dataField: 'doctor.name',
            text: 'Doctor',
            style: { wordBreak: 'break-word' },
            formatter: (cell) => cell || ''
        }, {
            dataField: 'doctor.specialization',
            text: 'Specialization',
            style: { wordBreak: 'break-word' },
            formatter: (cell) => cell || ''
        }, {
            formatter: this.actionFormatter,
            text: 'Actions',
            style: { width: '250px', whiteSpace: 'nowrap' },
            headerStyle: { width: '250px' },
            attrs: { style: { width: '250px' } }
        }];
    };

    actionFormatter = (cell, row) => {
        return (
            <>
                <a
                    href="javascript:;"
                    onClick={() => this.props.documentDownload(row.document_id)}
                    title="View Report"
                    className="cbtn cbtn-action-button">
                    📄 View
                </a>

                {row.doctor ?
                    <a
                        href="javascript:;"
                        onClick={() => this.revokeAccess(row.id)}
                        title="Revoke report access"
                        className="cbtn cbtn-action-button">
                        ➖ Revoke
                    </a>
                    :
                    <a
                        href="javascript:;"
                        onClick={() => this.openModal(row.id)}
                        title="Grant report access"
                        className="cbtn cbtn-action-button">
                        ➕ Grant
                    </a>
                }
                {/* Delete Button */}
                <a
                    href="javascript:;"
                    onClick={() => this.openDeleteModal(row.id)}
                    title="Delete Report"
                    className="cbtn cbtn-action-button">
                    🗑 Delete
                </a>
            </>
        );
    };
    openDeleteModal = (reportId) => {
        this.setState({ showDeleteModal: true, selectedReportId: reportId });
    };

    closeDeleteModal = () => {
        this.setState({ showDeleteModal: false, selectedReportId: null });
    };
    handleDeleteReport = () => {
            const { selectedReportId } = this.state;
            AuthService.deleteMedicalReport(this.props.patientId, selectedReportId)
                .then(resp => {
                    Notification.show('success', { message: "Report deleted successfully" });
                    this.closeDeleteModal();
                    // Refresh the report list after deletion
                    setTimeout(() => {
                        this.props.getPatientUploadedReportList(this.props.patientId, {});
                    }, 1000);
                })
                .catch(error => {
                    Notification.show('error', error.response.data);
                });
    };

    dateFormatter = (cell, row) => {
        if (row) {
            return HELPER.getDateFormat(row.created_at);
        }
    };

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
        if (type === "search") {
            page = 1;
        }
        this.props.getPatientUploadedReportList(this.props.patientId, { page, sizePerPage, sortBy: { sortField, sortOrder } });
    };

    openModal = (reportId) => {
        this.setState({ showModal: true, selectedReportId: reportId });
    };

    closeModal = () => {
        this.setState({
            showModal: false,
            selectedReportId: null,
            selectedSpecialization: '',
            doctorList: '',
            selectedDoctor: '',
        });
        this.validator.hideMessages();
    };

    onSpecializationChange = (query) => {
        if (query) {
            const filter = {
                role_slug: 'doctor',
                specialization: query
            };
            this.props.loaderShow();
            AuthService.searchDoctor({ filter }).then(resp => {
                this.setState({
                    selectedSpecialization: query,
                    doctorList: resp.data.data,
                    selectedDoctor: '',
                })
                this.props.loaderHide();
            }).catch(err => {
                this.props.loaderHide();
                Notification.show('error', { 'message': 'Something went wrong!' })
            })
        } else if (!query) {
            this.setState({
                selectedSpecialization: '',
                doctorList: '',
                selectedDoctor: '',
            })
        }
    }

    onDoctorChange = (evt) => {
        evt.persist();
        let doctor_profile_id = evt.target.value;
        if (doctor_profile_id) {
            this.setState({
                selectedDoctor: doctor_profile_id,
            })
        } else if (!doctor_profile_id) {
            this.setState({
                selectedDoctor: '',
            })
        }
    }

    grantAccess = () => {
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        const { selectedReportId, selectedDoctor } = this.state;

        AuthService.grantReportAccess(this.props.patientId, { report_id: selectedReportId, user_profile_id: selectedDoctor }).then(resp => {
            Notification.show('success', resp.data);

            this.closeModal();
            setTimeout(() => {
                this.props.getPatientUploadedReportList(this.props.patientId, {});
            }, 1000);
        }).catch(error => {
            Notification.show('error', error.response.data)
        })
    }

    revokeAccess = (reportId) => {
        AuthService.revokeReportAccess(this.props.patientId, reportId).then(resp => {
            Notification.show('success', resp.data);

            this.closeModal();
            setTimeout(() => {
                this.props.getPatientUploadedReportList(this.props.patientId, {});
            }, 1000);
        }).catch(error => {
            Notification.show('error', error.response.data)
        })
    }

    openUploadModal = () => {
        this.setState({ showUploadModal: true });
    };

    closeUploadModal = () => {
        this.setState({ showUploadModal: false, reportName: '', reportFile: null });
    };

    handleFileChange = (event) => {
        this.setState({ reportFile: event.target.files[0] });
    };

    handleReportNameChange = (event) => {
        this.setState({ reportName: event.target.value });
    };

    uploadReport = () => {
        const { reportName, reportFile } = this.state;
        if (!reportName || !reportFile) {
            Notification.show('error', { 'message': 'Please fill all fields' });
            return;
        }

        let formData = new FormData();
        formData.append('report_name', reportName);
        formData.append('file', reportFile);

        AuthService.uploadMedicalReport(this.props.patientId, formData)
            .then(resp => {
                Notification.show('success', { message: "Report uploaded successfully" });
                this.closeUploadModal();

                setTimeout(() => {
                    this.props.getPatientUploadedReportList(this.props.patientId, {});
                }, 1000);
            })
            .catch(error => {
                Notification.show('error', error.response.data);
            });
    };

    render() {
        this.validator.purgeFields();
        const { successResponse } = this.props.uploaded_report_list;
        const { showModal } = this.state;

        return (
            <div id="self-uploaded-report">
                <Form.Row className="justify-content-end">
                    <button className="cbtn cbtn-cancel button-size mb-2" onClick={this.openUploadModal}>
                        Upload Report
                    </button>
                </Form.Row>
                <DataGrid
                    columns={this.columns()}
                    data={successResponse.data}
                    meta={successResponse.meta}
                    handleTableChange={this.handleTableChange}
                    noDataIndication="No Record Found"
                />

                <Modal show={showModal} onHide={this.closeModal} size='md' centered aria-labelledby="contained-modal-title-vcenter" keyboard={false}>
                    <Modal.Header>
                        Grant report access to Doctor
                    </Modal.Header>
                    <Modal.Body>

                        <Form.Row>
                            <Form.Label className="col-12 mt-2">Select Specialization:</Form.Label>
                            <Form.Control as="select" className="col-12" value={this.state.selectedSpecialization} name="specialization" onChange={(e) => this.onSpecializationChange(e.target.value)}>
                                <option value="">Select Specialization</option>
                                {Object.keys(this.state.specializationList).map(function (key) {
                                    return <option key={key} value={key}>
                                        {key}
                                    </option>;
                                })}
                            </Form.Control>

                        </Form.Row>
                        <br />
                        <Form.Row>
                            <Form.Label className="col-12 mt-2">Select Doctor:</Form.Label>
                            <Form.Control as="select" className="col-12" name="selectedDoctor" value={this.state.selectedDoctor} onChange={this.onDoctorChange}>
                                <option value="">Select Doctor</option>
                                {this.state.doctorList && this.state.doctorList.map(doctor => (
                                    <option value={doctor.user_profile_id} key={doctor.user_profile_id}>{doctor.full_name}</option>
                                ))}
                            </Form.Control>
                        </Form.Row>
                        {this.validator.message('Specialization', this.state.selectedSpecialization, 'required')}
                        {this.validator.message('Doctor', this.state.selectedDoctor, 'required')}
                    </Modal.Body>
                    <Modal.Footer>
                        <a href="javascript:;" className="cbtn cbtn-submit" onClick={this.grantAccess}>Submit</a>
                        <a href="javascript:;" className="cbtn cbtn-cancel" onClick={this.closeModal}>Cancel</a>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showUploadModal} onHide={this.closeUploadModal} size='md' centered aria-labelledby="contained-modal-title-vcenter" keyboard={false}>
                    <Modal.Header>
                        Upload Report
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group>
                                <Form.Label>Report Name</Form.Label>
                                <Form.Control type="text" value={this.state.reportName} onChange={this.handleReportNameChange} placeholder="Enter report name" className="mt-2" />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Upload File</Form.Label>
                                <Form.Control type="file" onChange={this.handleFileChange} className="mt-2" />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <a href="javascript:;" className="cbtn cbtn-submit" onClick={this.uploadReport}>Upload</a>
                        <a href="javascript:;" className="cbtn cbtn-cancel" onClick={this.closeUploadModal}>Cancel</a>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.showDeleteModal} onHide={this.closeDeleteModal} size='md' centered>
                    <Modal.Header>
                        Confirm Delete
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to delete this file?
                    </Modal.Body>
                    <Modal.Footer>
                        <a href="javascript:;" className="cbtn cbtn-submit" onClick={this.handleDeleteReport}>Yes</a>
                        <a href="javascript:;" className="cbtn cbtn-cancel" onClick={this.closeDeleteModal}>Cancel</a>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.session.user,
    authenticated: state.session.authenticated,
    uploaded_report_list: state.uploaded_report_list
});

const mapActionsToProps = ({
    getPatientUploadedReportList: authAction.getPatientUploadedReportList,
    documentDownload: authAction.documentDownload,
    loaderShow: loaderAction.loaderShow,
    loaderHide: loaderAction.loaderHide,
});

export default connect(mapStateToProps, mapActionsToProps)(SelfUploadedMedicalReportTable);
