import * as types from './actionTypes';
import AuthService from '../services/authService';
import { sessionService } from 'redux-react-session';
import Notification from './../utils/notification';
import * as HELPERS from './../utils/helpers';
import * as authUtils from './../utils/auth'

export function login(name, password, history) {
    return dispatch => {
        Notification.clearAll();
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.LOGIN_REQUEST, payload: { name, password } });

        AuthService.login(name, password)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.LOGIN_SUCCESS, payload: data });

                sessionService.saveSession({ ...data.token })
                    .then(() => {
                        sessionService.saveUser(data.user)
                            .then(() => {
                                Notification.show('success', { message: 'Welcome, you have successfully logged in !' });
                                dispatch({ type: 'HIDE_PAGE_LOADER' });
                                history.push('/dashboard');
                            });
                    });
            })
            .catch(error => {
                dispatch({ type: types.LOGIN_FAILURE, payload: error.response });
                if (error.response) {
                    Notification.show('error', error.response.data);
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                }
            });
    }
}

export function registration(name, email, contact_number, password, password_confirmation, history) {
    return dispatch => {
        Notification.clearAll();
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.REGISTRATION_REQUEST, payload: { name, email, contact_number, password, password_confirmation } });

        AuthService.registration(name, email, contact_number, password, password_confirmation)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                dispatch({ type: types.REGISTRATION_SUCCESS, payload: data });
                // Notification.show('success', { message: 'You have successfully registered' });
                localStorage.setItem('contact_no', contact_number);
                history.push('/verify');
            })
            .catch(error => {
                dispatch({ type: types.LOGIN_FAILURE, payload: error.response });
                if (error.response) {
                    Notification.show('error', error.response.data);
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                }
            });
    }
}

export function registrationVerify(contact_number, otp, history) {
    return dispatch => {
        Notification.clearAll();
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.REGISTRATION_REQUEST, payload: { contact_number, otp } });

        AuthService.registrationVerify(contact_number, otp)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.REGISTRATION_SUCCESS, payload: data });
                localStorage.removeItem('contact_no');
                sessionService.saveSession({ ...data.token })
                    .then(() => {
                        sessionService.saveUser(data.user)
                            .then(() => {
                                Notification.show('success', { message: 'Welcome, you have successfully verify' });
                                dispatch({ type: 'HIDE_PAGE_LOADER' });
                                setTimeout(() => {
                                    history.push('/dashboard');
                                }, 1500)
                            });
                    });
            })
            .catch(error => {
                dispatch({ type: types.LOGIN_FAILURE, payload: error.response });
                if (error.response) {
                    Notification.show('error', error.response.data);
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                }
            });
    }
}
export function registrationOtpResend(contact_number) {
    return dispatch => {
        Notification.clearAll();
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        //dispatch({ type: types.ADD_NEW_PATIENT, payload: { patient_code: patient_code } });

        AuthService.registrationOtpResend(contact_number)
            .then(resp => {
                let data = resp.data;
                //dispatch({ type: types.ADD_NEW_PATIENT_SUCCESS, payload: { patient_code: patient_code, data } });
                Notification.show('success', data);
                dispatch({ type: 'HIDE_PAGE_LOADER' });
            })
            .catch(error => {
                //dispatch({ type: types.ADD_NEW_PATIENT_FAILURE, payload: error.response });
                if (error.response) {
                    Notification.show('error', error.response.data);
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                }
            });
    }
}

export function logout() {
    return dispatch => {
        Notification.clearAll();
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.LOGOUT_REQUEST });
        return AuthService.logout()
            .then(() => {
                dispatch({ type: types.LOGOUT_SUCCESS });
                sessionService.deleteSession()
                    .then(() => {
                        sessionService.deleteUser()
                            .then(() => {
                                authUtils.removeNotificationCount()
                                Notification.show('success', { message: 'Thank you, you have Successfully logged out!' });
                                dispatch({ type: 'HIDE_PAGE_LOADER' });
                            });
                    });
            })
            .catch(error => {
                dispatch({ type: types.LOGOUT_FAILURE, payload: error.response });
                if (error.response) {
                    Notification.show('error', error.response.data);
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                }
            });
    }
}

export function forgotPassword(contact_number, history) {
    return dispatch => {
        Notification.clearAll();
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.FORGOT_PASSWORD_REQUEST, payload: { contact_number } });

        AuthService.forgotPassword(contact_number)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.FORGOT_PASSWORD_SUCCESS, payload: { data } });
                Notification.show('success', data);
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                localStorage.setItem('contact_no', contact_number);
                history.push({
                    pathname: '/reset-password',
                    search: '?contact_number=' + contact_number,
                });
            })
            .catch(error => {
                dispatch({ type: types.FORGOT_PASSWORD_FAILURE, payload: error.response });
                if (error.response) {
                    Notification.show('error', error.response.data);
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                }
            });
    }
}
export function forgotPasswordResend(contact_number) {
    return dispatch => {
        Notification.clearAll();
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.FORGOT_PASSWORD_REQUEST, payload: { contact_number } });

        AuthService.forgotPassword(contact_number)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.FORGOT_PASSWORD_SUCCESS, payload: { data } });
                Notification.show('success', data);
                dispatch({ type: 'HIDE_PAGE_LOADER' });
            })
            .catch(error => {
                dispatch({ type: types.FORGOT_PASSWORD_FAILURE, payload: error.response });
                if (error.response) {
                    Notification.show('error', error.response.data);
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                }
            });
    }
}


export function resetPassword(contact_number, otp, password, password_confirmation, history) {
    return dispatch => {
        Notification.clearAll();
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.RESET_PASSWORD_REQUEST, payload: { otp, password, password_confirmation } });

        AuthService.resetPassword(contact_number, otp, password, password_confirmation)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.RESET_PASSWORD_SUCCESS, payload: { data } });
                Notification.show('success', data);
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                localStorage.removeItem('contact_no');
                history.push('/login');
            })
            .catch(error => {
                dispatch({ type: types.RESET_PASSWORD_FAILURE, payload: error.response });
                if (error.response) {
                    Notification.show('error', error.response.data);
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                }
            });
    }
}

export function changePassword(stateData, history) {
    return dispatch => {
        Notification.clearAll();
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.CHANGE_PASSWORD_REQUEST, payload: { stateData } });

        AuthService.changePassword(stateData)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.CHANGE_PASSWORD_SUCCESS, payload: { data } });
                Notification.show('success', data);
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                history.push('/dashboard');
            })
            .catch(error => {
                dispatch({ type: types.CHANGE_PASSWORD_FAILURE, payload: error.response });
                if (error.response) {
                    Notification.show('error', error.response.data);
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                }
            });
    }
}

export function editProfile(name, email, history) {
    return dispatch => {
        Notification.clearAll();
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.EDITPROFILE_REQUEST, payload: { name: name, email: email } });
        AuthService.editProfile(name, email)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.EDITPROFILE_SUCCESS, payload: { data } });

                let user = HELPERS.getAuthUser();
                user.full_name = name;
                user.email = email;

                sessionService.saveUser(user)
                    .then(() => {
                        Notification.show('success', data);
                        dispatch({ type: 'HIDE_PAGE_LOADER' });
                        history.push('/dashboard');
                    });
            })
            .catch(error => {
                dispatch({ type: types.EDITPROFILE_FAILURE, payload: error.response });
                if (error.response) {
                    Notification.show('error', error.response.data);
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                }
            });
    }
}


export function addPatient(name) {
    return dispatch => {
        Notification.clearAll();
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.NEW_PASSWORD_REQUEST, payload: { name } });

        AuthService.newPassword(name)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.NEW_PASSWORD_SUCCESS, payload: { data } });
                Notification.show('success', data);
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                //history.push('/login');
            })
            .catch(error => {
                dispatch({ type: types.NEW_PASSWORD_FAILURE, payload: error.response });
                if (error.response) {
                    Notification.show('error', error.response.data);
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                }
            });
    }
}

export function addPatientCode(patient_code, history) {
    return dispatch => {
        Notification.clearAll();
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.ADD_NEW_PATIENT, payload: { patient_code: patient_code } });

        AuthService.addPatientCode(patient_code)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.ADD_NEW_PATIENT_SUCCESS, payload: { patient_code: patient_code, data } });
                Notification.show('success', data);
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                localStorage.setItem('patient_key', patient_code);
                localStorage.setItem('patient_contact_number', data.contact_number);
                history.push('/otp');

            })
            .catch(error => {
                dispatch({ type: types.ADD_NEW_PATIENT_FAILURE, payload: error.response });
                if (error.response) {
                    Notification.show('error', error.response.data);
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                }
            });
    }
}
export function patientOtp(otp, patient_code, history) {
    return dispatch => {
        Notification.clearAll();
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.PATIENT_OTP, payload: { otp } });

        AuthService.patientOtp(otp, patient_code)
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.PATIENT_OTP_SUCCESS, payload: { data } });
                Notification.show('success', { message: 'Welcome, you have successfully verifed.' });
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                localStorage.removeItem('patient_key');
                localStorage.removeItem('patient_contact_number');
                setTimeout(() => {
                    history.push('/dashboard');
                }, 1500);

            })
            .catch(error => {
                dispatch({ type: types.PATIENT_OTP_FAILURE, payload: error.response });
                if (error.response) {
                    Notification.show('error', error.response.data);
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                }
            });
    }
}
export function resendOtp(patient_code) {
    return dispatch => {
        Notification.clearAll();
        dispatch({ type: 'SHOW_PAGE_LOADER' });

        AuthService.addPatientCode(patient_code)
            .then(resp => {
                let data = resp.data;
                Notification.show('success', data);
                dispatch({ type: 'HIDE_PAGE_LOADER' });
            })
            .catch(error => {
                if (error.response) {
                    Notification.show('error', error.response.data);
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                }
            });
    }
}

export function getPatient() {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.PATIENTS_REQUEST, payload: {} });

        AuthService.getPatient()
            .then(resp => {
                let data = resp.data;

                dispatch({ type: types.PATIENTS_SUCCESS, payload: data });
                dispatch({ type: 'HIDE_PAGE_LOADER' });
            })
            .catch(error => {
                if (error.response) {
                    Notification.show('error', error.response.data);
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                }
            });
    }
}

export function getPatientById(patientId) {
    return dispatch => {
        Notification.clearAll();
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.PATIENT_REQUEST, payload: {} });

        AuthService.getPatientById(patientId)
            .then(resp => {
                let data = resp.data;

                dispatch({ type: types.PATIENT_SUCCESS, payload: data, requestedPatientId: patientId });
                dispatch({ type: 'HIDE_PAGE_LOADER' });
            })
            .catch(error => {
                if (error.response) {
                    dispatch({ type: types.PATIENT_FAILURE, payload: {} });
                    Notification.show('error', error.response.data);
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                }
            });
    }
}

export function getPrescriptionList(patientId, { page, sizePerPage, sortBy }) {
    return dispatch => {
        Notification.clearAll();
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.PRISCRIPTIONLIST_REQUEST, payload: { patientId: patientId, page, sizePerPage, sortBy } });

        AuthService.getPrescriptionList(patientId, { page, sizePerPage, sortBy })
            .then(resp => {
                let data = resp.data;

                dispatch({ type: types.PRISCRIPTIONLIST_SUCCESS, payload: data });
                dispatch({ type: 'HIDE_PAGE_LOADER' });


            })
            .catch(error => {
                if (error.response) {
                    Notification.show('error', error.response.data);
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                }
            });
    }
}

export function getOtherPrescriptions(patientId, { page, sizePerPage, sortBy }) {
    return dispatch => {
        Notification.clearAll();
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.OTHER_PRESCRIPTION_LIST_REQUEST, payload: { patientId: patientId, page, sizePerPage, sortBy } });

        AuthService.getOtherPrescription(patientId, { page, sizePerPage, sortBy })
            .then(resp => {
                let data = resp.data;

                dispatch({ type: types.OTHER_PRESCRIPTION_LIST_SUCCESS, payload: data });
                dispatch({ type: 'HIDE_PAGE_LOADER' });
            })
            .catch(error => {
                dispatch({ type: types.OTHER_PRESCRIPTION_LIST_FAILED, payload: error.response.data });
                if (error.response) {
                    Notification.show('error', error.response.data);
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                }
            });
    }
}

export function getPathologyList(patientId, { page, sizePerPage, sortBy, filter}) {
    return dispatch => {
        Notification.clearAll();
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.PATHOLOGYLIST_REQUEST, payload: { patientId: patientId, page, sizePerPage, sortBy } });

        AuthService.getPathologyList(patientId, { page, sizePerPage, sortBy, filter })
            .then(resp => {
                let data = resp.data;

                dispatch({ type: types.PATHOLOGYLIST_SUCCESS, payload: data });
                dispatch({ type: 'HIDE_PAGE_LOADER' });


            })
            .catch(error => {
                if (error.response) {
                    Notification.show('error', error.response.data);
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                }
            });
    }
}

export function getRadiologyList(patientId, { page, sizePerPage, sortBy, filter }) {
    return dispatch => {
        Notification.clearAll();
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.RADIOLOGYLIST_REQUEST, payload: { patientId: patientId, page, sizePerPage, sortBy } });

        AuthService.getRadiologyList(patientId, { page, sizePerPage, sortBy, filter })
            .then(resp => {
                let data = resp.data;

                dispatch({ type: types.RADIOLOGYLIST_SUCCESS, payload: data });
                dispatch({ type: 'HIDE_PAGE_LOADER' });


            })
            .catch(error => {
                if (error.response) {
                    Notification.show('error', error.response.data);
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                }
            });
    }
}

export function getPrescriptionDetail(patientId, prescriptionId) {
    return dispatch => {
        Notification.clearAll();
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.PRESCRIPTIONDETAIL_REQUEST, payload: { prescriptionId: prescriptionId, patientId: patientId } });

        AuthService.getPrescriptionDetail(patientId, prescriptionId)
            .then(resp => {
                let data = resp.data;

                dispatch({ type: types.PRESCRIPTIONDETAIL_SUCCESS, payload: data });
                dispatch({ type: 'HIDE_PAGE_LOADER' });
            })
            .catch(error => {
                if (error.response) {
                    Notification.show('error', error.response.data);
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                }
            });
    }
}

export function documentDownload(documentId) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });

        AuthService.getDocumentDownloadUrl(documentId)
            .then(resp => {
                let data = resp.data;
                window.open(data.download_url);

                dispatch({ type: 'HIDE_PAGE_LOADER' });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    let errorData = error.response.data;
                    Notification.show('error', errorData)
                }
            });
    }
}

export function getPatientCertificates(patientId, { page, sizePerPage, sortBy }) {
    return dispatch => {
        Notification.clearAll();
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.PATIENT_CERTIFICATE_LIST_REQUEST, payload: { patientId: patientId, page, sizePerPage, sortBy } });

        AuthService.getPatientsCertificates(patientId, { page, sizePerPage, sortBy })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.PATIENT_CERTIFICATE_LIST_SUCCESS, payload: data });
                dispatch({ type: 'HIDE_PAGE_LOADER' });
            })
            .catch(error => {
                dispatch({ type: types.PATIENT_CERTIFICATE_LIST_FAILED, payload: error.response });
                if (error.response) {
                    Notification.show('error', error.response.data);
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                }
            });
    }
}

export function getPatientUserNotification() {
    return dispatch => {
        Notification.clearAll();
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.PATIENT_USER_NOTIFICATION_REQUEST, payload: {} });

        AuthService.getPatientUserNotification()
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.PATIENT_USER_NOTIFICATION_SUCCESS, payload: data });
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                return dispatch(getNotificationCount());
            })
            .catch(error => {
                dispatch({ type: types.PATIENT_USER_NOTIFICATION_FAILED, payload: error.response });
                if (error.response) {
                    Notification.show('error', error.response.data);
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                }
            });
    }
}

export function getNotificationCount() {
    return dispatch => {
        AuthService.getNotificationCount().then(resp => {
            const { notification_count } = resp.data;
            const currentNotificationCount = authUtils.getNotificationCount();
            if (notification_count > 0 && parseInt(currentNotificationCount) !== notification_count) {
                Notification.show('info', { message: 'There are some new notifications.' })
            }
            authUtils.setNotificationCount(notification_count);
            dispatch({ type: types.USER_NOTIFICATION_COUNT, payload: resp.data });
        }).catch(error => {
            if (error.response && error.response.status) {
                let errorData = error.response.data;
                Notification.show('error', errorData)
            }
        })
    }
}

export function getPatientHistoricData(patientId, { page, sizePerPage, sortBy }) {
    return dispatch => {
        Notification.clearAll();
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.PATIENT_HISTORIC_DATA_LIST_REQUEST, payload: { patientId: patientId, page, sizePerPage, sortBy } });

        AuthService.getPatientHistoricData(patientId, { page, sizePerPage, sortBy })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: types.PATIENT_HISTORIC_DATA_LIST_SUCCESS, payload: data });
                dispatch({ type: 'HIDE_PAGE_LOADER' });
            })
            .catch(error => {
                dispatch({ type: types.PATIENT_HISTORIC_DATA_LIST_FAILED, payload: error.response });
                if (error.response) {
                    Notification.show('error', error.response.data);
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                }
            });
    }
}

export function getPatientUploadedReportList(patientId, { page, sizePerPage, sortBy }) {
    return dispatch => {
        Notification.clearAll();
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.PATIENT_UPLOADED_REPORT_LIST_REQUEST, payload: { patientId: patientId, page, sizePerPage, sortBy } });

        AuthService.getPatientUploadedReportList(patientId, { page, sizePerPage, sortBy })
            .then(resp => {
                let data = resp.data;

                dispatch({ type: types.PATIENT_UPLOADED_REPORT_LIST_SUCCESS, payload: data });
                dispatch({ type: 'HIDE_PAGE_LOADER' });


            })
            .catch(error => {
                if (error.response) {
                    Notification.show('error', error.response.data);
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                }
            });
    }
}

export function removeUserSession() {
    return dispatch => {
        Notification.clearAll();
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        sessionService.deleteSession()
        sessionService.deleteUser()
        authUtils.removeNotificationCount()
        dispatch({ type: 'HIDE_PAGE_LOADER' });
        return true;
    }
}

export const deleteMedicalReport = (patientId, reportId) => {
    return dispatch => {
        return AuthService.deleteMedicalReport(patientId, reportId);
    };
};
